import type { BridgeInterface} from '@daangn/business-sdk-webview';
import { BusinessSdkWebview } from '@daangn/business-sdk-webview';
import authPlugin from '@daangn/plantae-plugin-auth';
import { createAxiosInterceptors } from 'plantae/axios';

import { bridge } from '../bridge';
import { IS_PROD } from '../constants';

const bridgeInterface: BridgeInterface = {
    router: {
      push: (params) => {
        return bridge.pushRouter({ router: {
          ...params,
          navbar: params.navbar ?? false,
          scrollable: params.scrollable ?? false,
        } })},
    },
    analytics: {
      log: (event) => {
        return bridge.logEvent({ analytics: {
          name: event.name,
          params: JSON.stringify(event.params),
          target: event.target,
        } })
      },
    },
    toast: {
      open: (message) => {
        return bridge.openToast({ toast: { body: message } });
      },
    },
    environment: {
      get: () => {
        return bridge.driver.getCurrentEnvironment()
      },
    },
    stream: {
      emit: (eventName, payload) => {
        return bridge.emitToStream({
          stream: {
            eventName,
            data: JSON.stringify(payload),
          },
        })
      },
      on: (eventName, callback) => {
        return bridge.driver.compat.subscribeStream(eventName, callback)
      },
    },
    storage: {
      get: async (key) => {
        return (await bridge.getItemInStorage({ storage: { key } })).storage.value
      },
      set: async (key, value) => {
        await bridge.setItemInStorage({ storage: { key, value: JSON.stringify(value) } })
      },
      remove: async (key) => {
        await bridge.deleteItemInStorage({ storage: { key } })
      },
    },
  };
  export const businessSDK = new BusinessSdkWebview(IS_PROD, bridgeInterface);

  export const { request, response} = createAxiosInterceptors({
    client: businessSDK.core.req,
    plugins: [
      authPlugin({
        bridge: bridge,
        options: {
          fallbackAuthToken: true,
          shouldRefreshOnUnauthorized: (res) => {
            if (res.status !== 401) {
              return false;
            }
  
            return res.headers.get('X-Unauthorized-By') === 'access-token-expired';
          },
        },
      }),
    ],
  });

  console.log("INITIALIZE PLANTAE");


  businessSDK.core.req.interceptors.request.use(request.onFulfilled as any, request.onRejected as any);
  businessSDK.core.req.interceptors.response.use(response.onFulfilled as any, response.onRejected as any);

