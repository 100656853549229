import { useQuery } from '@tanstack/react-query';

import { businessSDK } from './businessSDK';

export const loadBusinessAccountList = async () => {
  try {
    return await businessSDK.core.profileAPI.reqBizAccountList({
      includeExtra: true,
    });
  } catch (error) {
    throw error;
  }
};

export const useLoadbizAccountList = () => {


  const { data, isPending, isError } = useQuery({
    queryFn: async () => await loadBusinessAccountList(),
    queryKey: ['bizAccountList'],
  });

  return { data, isPending, isError };
};
