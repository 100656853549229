import { installKarrotBridgeDriver, makeKarrotBridge } from '@daangn/karrotbridge';
// import { Bridge } from '@daangn/webview-bridge-modern';
// import { PluginAnalytics } from '@daangn/webview-bridge-modern/lib/plugins/Analytics';
// import { PluginRouter } from '@daangn/webview-bridge-modern/lib/plugins/Router';
// import { PluginStorage } from '@daangn/webview-bridge-modern/lib/plugins/Storage';
// import { PluginToast } from '@daangn/webview-bridge-modern/lib/plugins/Toast';
import React, { createContext, useContext } from 'react';

/**
 * 네이티브 앱과 통신하기 위한 Bridge에요.
 * 어떤 기능이 있는지 살펴보고 싶으시다면 아래 방법을 통해 실험실에 방문해보세요.
 * - iOS 앱: "나의 당근" -> "앱 설정" -> "미니캐롯 웹뷰 실험실 열기"
 * - 안드로이드 앱 (디버그 버전): "나의 당근" -> 우측 끝에서 좌로 스와이프 -> "웹뷰 샘플 시작하기"
 *
 * Bridge를 프로덕션 환경에서 사용하기 위해서는
 * xproperty 내에 webview_url_allowlist에 도메인 값을 추가해주어야 해요.
 * 추가하러가기: https://xproperty.kr.karrotmarket.com/admin/edit?pkey=webview_url_allowlist&country=KR
 *
 * 만약 네이티브 개발자와 함께 브릿지에 새로운 인터페이스를 기여하고 싶으시다면,
 * 아래 GitHub을 살펴보세요.
 * https://github.com/daangn/karrotbridge
 */
const { driver } = installKarrotBridgeDriver();
export const bridge = makeKarrotBridge({ driver });

// /**
//  * @deprecated businessSDK 호출시에만 사용해요
//  */
// export const legacyBridge = new Bridge({
//   dangerouslyInjectDriver: driver,
// })
//   .addPlugin(PluginRouter)
//   .addPlugin(PluginStorage)
//   .addPlugin(PluginToast)
//   .addPlugin(PluginAnalytics);

const BridgeContext = createContext(bridge);

export type KarrotBridgeDriver = ReturnType<typeof installKarrotBridgeDriver>['driver'];

export interface KarrotBridge extends ReturnType<typeof makeKarrotBridge> {
  driver: KarrotBridgeDriver;
}

export const BridgeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <BridgeContext.Provider value={bridge}>{children}</BridgeContext.Provider>;
};

export const useBridge = () => {
  return useContext(BridgeContext);
};
