import { karrotAnalyticsPlugin } from '@daangn/stackflow-plugin-karrot-analytics';
import { vars } from '@seed-design/design-token';
import { basicUIPlugin } from '@stackflow/plugin-basic-ui';
import { historySyncPlugin } from '@stackflow/plugin-history-sync';
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic';
import type { ActivityComponentType } from '@stackflow/react';
import { stackflow } from '@stackflow/react';

import { bridge } from '../bridge';
import { activities, routes } from '.';
import globalConfigPlugin from './globalConfigPlugin';
import KarrotUserAuthPlugin from './KarrotUserAuthPlugin';

/**
 * Stackflow는 웹뷰 내에서 Stack Navigation UI를 도와주는 도구에요.
 * 아래 웹사이트를 통해, 가이드 문서를 확인하실 수 있어요.
 * 웹사이트: https://stackflow.so
 * GitHub: https://github.com/daangn/stackflow
 */

const theme = /iphone|ipad|ipod/i.test(window.navigator.userAgent.toLowerCase()) ? 'cupertino' : 'android';

export const createStack = (history?: History) =>
  stackflow({
    transitionDuration: 270,
    activities: activities,
    plugins: [
      basicRendererPlugin(),
      KarrotUserAuthPlugin(),

      basicUIPlugin({
        theme,
        backgroundColor: vars.$semantic.color.paperDefault,
        appBar: {
          borderColor: 'none',
          textColor: vars.$semantic.color.inkText,
          iconColor: vars.$semantic.color.inkText,
        },
      }),
      historySyncPlugin({
        routes: routes(),
        fallbackActivity: () => 'ActivityHome',
      }),
      globalConfigPlugin(),
      karrotAnalyticsPlugin({
        bridge,
        serviceName: 'biz-pop',
      }),
    ],
  });

export type TypeUseFlow = ReturnType<typeof createStack>['useFlow'];
export type TypeUseStepFlow = ReturnType<typeof createStack>['useStepFlow'];
export type TypeActivities = typeof activities;
export type TypeActivitiesKeys = keyof TypeActivities;
export type TypeActivityParams<K extends TypeActivitiesKeys> = TypeActivities[K] extends
  | ActivityComponentType<infer U>
  | {
      component: ActivityComponentType<infer U>;
    }
  ? U
  : {};
